export class AnswerSettings {
  static QUESTION_TYPE_SIMPLE = 'simple'
  static QUESTION_TYPE_RANKING = 'ranking'
  static DEFAULT_QUESTION_TYPE = AnswerSettings.QUESTION_TYPE_SIMPLE

  constructor (settings = {}) {
    this.type = settings.question_type || AnswerSettings.DEFAULT_QUESTION_TYPE
    this.isValid = settings.valid || false
    this.key = settings.answer_key || ''
  }
}

export class Answer {
  constructor (answer = {}) {
    this.id = answer.answer_id || 0
    this.questionId = answer.question_id || 0
    this.answer = answer.answer || ''
    this.grade = answer.grade || 0
    this.comments = answer.comments || ''
    this.settings = new AnswerSettings(answer.settings)
  }
}

export class QuestionAnswer extends Answer {
  _userAnswers = []

  constructor (answer, userAnswers = []) {
    super(answer)
    this._userAnswers = userAnswers
  }

  get isDefaultType () {
    return this.settings.type === AnswerSettings.DEFAULT_QUESTION_TYPE
  }

  get isSimpleType () {
    return this.settings.type === AnswerSettings.QUESTION_TYPE_SIMPLE
  }

  get isRankingType () {
    return this.settings.type === AnswerSettings.QUESTION_TYPE_RANKING
  }

  get isSelected () {
    return this._userAnswers.some(answer => answer.answer_id === this.id)
  }
}
