<template>
  <div
    v-if="!isReadonly"
    class="task__answers"
  >
    <VRadioGroup
      v-if="!isMultipleChoice"
      :value="value[0]"
      hide-details
      class="ma-0 pa-0"
      @change="$emit('input', [$event])"
    >
      <TrainingQuizAnswer
        v-for="(answer, idx) in answers"
        :key="answer.id"
        class="my-2"
        :answer="answer"
        :index="idx"
        :is-revealed="isRevealed"
      >
        <VRadio
          :value="answer.id"
          color="success"
          :label="answer.answer"
        />
      </TrainingQuizAnswer>
    </VRadioGroup>

    <template v-else>
      <p>{{ $t('question_may_have_several_answers.one') }}.</p>

      <TrainingQuizAnswer
        v-for="(answer, idx) in answers"
        :key="answer.id"
        :answer="answer"
        :index="idx"
        :is-revealed="isRevealed"
      >
        <VCheckbox
          :input-value="value"
          :value="answer.id"
          hide-details
          color="success"
          :label="answer.answer"
          @change="$emit('input', $event)"
        />
      </TrainingQuizAnswer>
    </template>
  </div>

  <ul
    v-else
    class="task__answers"
  >
    <TrainingQuizAnswer
      v-for="(answer, idx) in answers"
      :key="answer.id"
      :answer="answer"
      :index="idx"
      :is-revealed="isRevealed"
      :is-readonly="isReadonly"
      :is-show-correct-answer="isShowCorrectAnswer"
      tag="li"
    />
  </ul>
</template>

<script>
import TrainingQuizAnswer from '@components/TrainingQuizAnswer.vue'

export default {
  name: 'TrainingQuizQuestionTypeSimple',

  components: {
    TrainingQuizAnswer
  },

  props: {
    value: {
      type: Array,
      default: () => []
    },

    isMultipleChoice: {
      type: Boolean,
      default: false
    },

    isRevealed: {
      type: Boolean,
      default: false
    },

    isReadonly: {
      type: Boolean,
      default: false
    },

    isShowCorrectAnswer: {
      type: Boolean,
      default: false
    },

    answers: {
      type: Array,
      default: () => []
    }
  }
}
</script>
